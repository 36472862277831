import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts/default'

import iconStart from '../assets/img/icon-rocket.svg'
import iconUse from '../assets/img/icon-desktop.svg'
import iconBilling from '../assets/img/icon-billing.svg'
import iconFeature from '../assets/img/icon-research.svg'
import iconIntegrations from '../assets/img/icon-integrations.svg'

const IndexPage = () => (
	<Layout>
		<div className="container">
			<h1 className="text-white text-center mt-5 mb-5" style={{letterSpacing: '0.06em'}}>Welcome! <span style={{fontWeight: 200 }}> How can we help?</span></h1>

			<div className="row justify-content-center">



				<div className="col-md-3 mb-4">
					<div className="card card-feature">
						<Link to="/getting-started/">
							<div className="card-body text-center">
								<img src={iconStart} className="img-fluid" style={{padding: 15}} alt="Getting Started"/>
								<h5 style={{color: '#00b28b', paddingTop: 10, paddingBottom: 10}}>Getting Started</h5>
							</div>
						</Link>
					</div>
				</div>


				<div className="col-md-3 mb-4">
					<div className="card card-feature">
						<Link to="/integrations/">
							<div className="card-body text-center">
								<img src={iconIntegrations} className="img-fluid" style={{padding: 15}} alt="Integrations"/>
								<h5 style={{color: '#00b28b', paddingTop: 10, paddingBottom: 10}}>Integrations</h5>
							</div>
						</Link>
					</div>
				</div>


			{/*
				<div className="col-md-3 mb-4">
					<div className="card card-feature">
						<Link to="/using-reldesk/">
							<div className="card-body text-center">
								<img src={iconUse} className="img-fluid" style={{padding: 15}} alt="Using Reldesk"/>
								<h5 style={{color: '#00b28b', paddingTop: 10, paddingBottom: 10}}>Using Reldesk</h5>

							</div>
						</Link>
					</div>
				</div>
				<div className="col-md-3 mb-4">
					<div className="card card-feature">
						<Link to="/accounts/">
							<div className="card-body text-center">
								<img src={iconBilling} className="img-fluid" style={{padding: 15}} alt="Accounts & Billing"/>
								<h5 style={{color: '#00b28b', paddingTop: 10, paddingBottom: 10}}>Account & Billing</h5>

							</div>
						</Link>
					</div>
				</div>
				 */}
				<div className="col-md-3 mb-4">
					<div className="card card-feature">
						<Link to="/suggestions/">
							<div className="card-body text-center">
								<img src={iconFeature} className="img-fluid" style={{padding: 15}} alt="Suggest a Feature"/>
								<h5 style={{color: '#00b28b', paddingTop: 10, paddingBottom: 10}}>Suggest a Feature</h5>

							</div>
						</Link>
					</div>
				</div>

				
			</div>
		</div>

	</Layout>
)

export default IndexPage
